import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import { useGetDepositTableFiltersQuery, useLazyGetDepositsQuery } from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useGetTableFilterOptions } from '~/hooks';
import { WithBookmarks } from '~/modules/bookmarks/components/WithBookmarks';
import { TBookmarkResponseProps } from '~/modules/bookmarks/hooks/useBookmark/types';
import { PATHS } from '~/router';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import {
  calculateCountOfPages,
  getAdditionalFilters,
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';
import { getSortingParams } from '~/utils/table/sorting';

import {
  defaultSortBy,
  DEPOSIT_DATE_FIELDS,
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
} from './Deposits.constants';
import { getRowProps } from './Deposits.helpers';
import { TDepositsProps } from './Deposits.types';

export const Deposits: FC<TDepositsProps & TBookmarkResponseProps> = ({
  tableId,
  pageName,
  selectedBookmark,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleSaveBookmark,
  handleShareBookmark,
}) => {
  const navigate = useNavigate();

  const { data: depositTableFilters } = useGetDepositTableFiltersQuery();
  const [fetchDeposits] = useLazyGetDepositsQuery();

  const additionalOptions = useGetTableFilterOptions(depositTableFilters);

  const getDeposits = useCallback(
    async (params: IOnFetchArguments) => {
      // eslint-disable-next-line no-console
      console.log('getDeposits params', params);

      const paginationParams = getPaginationParams(params);
      const filterParams = prepareFilterParams(
        getFilterParams(params, DEPOSIT_DATE_FIELDS),
      );
      const sortingParams = getSortingParams(params);

      const response = await fetchDeposits({
        ...paginationParams,
        ...filterParams,
        ...sortingParams,
      });

      return response.data;
    },
    [fetchDeposits],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getDeposits },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getDeposits, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
    setSorting,
  } = useTableData(tableDataArgs);

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: depositTableFilters,
        additionalOptions,
      }),
    [
      removeFilter,
      setFilter,
      depositTableFilters,
      Object.keys(additionalOptions),
    ],
  );
  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const total = data?.iTotalDisplayRecords || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const columns = getColumns();

  const bookmarkComponent = useMemo(() => {
    if (!selectedBookmark) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark?.columns.length
        ? selectedBookmark?.columns
        : DISPLAYED_COLUMNS_KEYS,
    [selectedBookmark?.columns],
  );

  const handleRowClick = useCallback(
    ({ id }: TDepositInfo) => {
      navigate(`${PATHS.DEPOSITS}/${id}`);
    },
    [navigate],
  );

  const additionalActions = [
    {
      component: (
        <RefreshButton
          onRefresh={fetchData}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TDepositInfo>
      className="DepositInfoTable"
      title={pageName}
      filtersRightPanelComponent={bookmarkComponent}
      columns={columns}
      isLoading={isLoading}
      displayedColumnKeys={displayedColumnKeys}
      filtersExpanded
      isFlexLayout
      defaultSortBy={defaultSortBy}
      onSort={setSorting}
      manualSortBy
      hasFilters
      hasNegativeFilters
      handleRowClick={handleRowClick}
      filteringProps={filterProps}
      data={data?.results || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      serverPaginationProps={serverPaginationProps}
      getRowProps={getRowProps}
      saveColumnOrder
      isNotSaveVisibleColumns={!!selectedBookmark?.id}
      saveViewParamsAfterLeave
      additionalActions={additionalActions}
    />
  );
};

export const DepositsContainer = () => {
  return (
    <WithBookmarks
      component={Deposits}
      pageName="Deposits"
      tableId="deposits"
    />
  );
};
