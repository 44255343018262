import { FC, useCallback, useMemo, useState, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IconButton,
  IOnFetchArguments,
  IRow,
  Loader,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useExportWithdrawalCsvMutation,
  useGetWithdrawalsTableFiltersQuery,
  useLazyGetWithdrawalsQuery,
} from '~/api';
import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useGetTableFilterOptions } from '~/hooks';
import { WithBookmarks } from '~/modules/bookmarks/components/WithBookmarks';
import {
  TBookmarkLoadedProps,
  TBookmarkResponseProps,
} from '~/modules/bookmarks/hooks/useBookmark/types';
import { PATHS } from '~/router';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import {
  calculateCountOfPages,
  getAdditionalFilters,
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';

import { defaultSortByGlobal } from '../../constants/table';
import { getSortingParams } from '../../utils/table/sorting';

import {
  DISPLAYED_COLUMNS_KEYS,
  fieldsParams,
  getColumns,
  WITHDRAWALS_DATE_FIELDS,
} from './Withdrawals.constants';
import { getRowProps } from './Withdrawals.helpers';

const Withdrawals: FC<TBookmarkResponseProps & TBookmarkLoadedProps> = ({
  tableId,
  pageName,
  selectedBookmark,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleSaveBookmark,
  handleShareBookmark,
}) => {
  const navigate = useNavigate();

  const { data: withdrawalsTableFilters } =
    useGetWithdrawalsTableFiltersQuery();
  const [fetchWithdrawals, { isFetching: isFetchingWithdrawals }] =
    useLazyGetWithdrawalsQuery();
  const [exportWithdrawalCsv, { isLoading: isLoadingExportCsv }] =
    useExportWithdrawalCsvMutation();

  const [selectedWithdrawal, setSelectedWithdrawal] = useState<
    IRow<TWithdrawalsData>[]
  >([]);
  const [toggleAllRowsSelected, setToggleAllRowsSelected] = useState<
    Dispatch<any>
  >(() => {});

  const handleSelectWithdrawal = useCallback(
    (value: IRow<TWithdrawalsData>[] | IRow<TWithdrawalsData>) => {
      setSelectedWithdrawal((prev) => {
        if (Array.isArray(value)) {
          return value;
        }

        const id = value.values.id;

        if (
          prev.some((item) => {
            return item.values.id === id;
          })
        ) {
          return prev.filter((row) => {
            return row.values.id !== id;
          });
        }

        return [...prev, value];
      });
    },
    [setSelectedWithdrawal],
  );

  const additionalOptions = useGetTableFilterOptions(withdrawalsTableFilters);

  const getWithdrawals = useCallback(async (params: IOnFetchArguments) => {
    const paginationParams = getPaginationParams(params);
    const filterParams = prepareFilterParams(
      getFilterParams(params, WITHDRAWALS_DATE_FIELDS),
    );
    const sortingParams = getSortingParams(params);

    const response = await fetchWithdrawals({
      ...paginationParams,
      ...filterParams,
      ...fieldsParams,
      ...sortingParams,
    });
    return response.data;
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getWithdrawals },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getWithdrawals, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
    setSorting,
  } = useTableData(tableDataArgs);

  const isTableLoading =
    isLoading || isLoadingExportCsv || isFetchingWithdrawals;

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: withdrawalsTableFilters,
        additionalOptions,
      }),
    [
      removeFilter,
      setFilter,
      withdrawalsTableFilters,
      Object.keys(additionalOptions),
    ],
  );

  const columns = getColumns();

  const total = data?.iTotalDisplayRecords || 0;

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters, selectedBookmark],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const bookmarkComponent = useMemo(() => {
    if (!selectedBookmark) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark?.columns.length
        ? selectedBookmark?.columns
        : DISPLAYED_COLUMNS_KEYS,
    [selectedBookmark?.columns],
  );

  const exportWithdrawalCsvAndReset = () => {
    exportWithdrawalCsv({
      columns: displayedColumnKeys,
      withdrawals_ids: selectedWithdrawal.map((item) => item?.values?.id),
    });

    toggleAllRowsSelected(false);
    setSelectedWithdrawal([]);
  };

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={fetchData}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
    {
      key: 'export',
      component: isLoadingExportCsv ? (
        <Loader />
      ) : (
        <IconButton
          iconName="ExportIcon"
          iconSize={24}
          title="Export to CSV"
          disabled={selectedWithdrawal.length === 0}
          onClick={exportWithdrawalCsvAndReset}
        />
      ),
    },
  ];

  const handleRowClick = useCallback(
    ({ id }: TWithdrawalsData) => {
      navigate(`${PATHS.WITHDRAWALS}/${id}`);
    },
    [navigate],
  );

  return (
    <Table<TWithdrawalsData>
      title={pageName}
      filtersRightPanelComponent={bookmarkComponent}
      columns={columns}
      isLoading={isTableLoading}
      displayedColumnKeys={displayedColumnKeys}
      filtersExpanded
      isFlexLayout
      onSort={setSorting}
      defaultSortBy={defaultSortByGlobal}
      manualSortBy
      hasFilters
      hasNegativeFilters
      filteringProps={filterProps}
      data={data?.data || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      isNotSaveVisibleColumns={!!selectedBookmark?.id}
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      getRowProps={getRowProps}
      additionalActions={additionalActions}
      saveColumnOrder
      bulkActions={{
        setToggleAllRowsSelected,
        selectedRows: selectedWithdrawal,
        setSelectedRows: handleSelectWithdrawal,
      }}
      handleRowClick={handleRowClick}
    />
  );
};

export const WithdrawalsContainer = () => {
  return (
    <WithBookmarks
      component={Withdrawals}
      pageName="Withdrawals"
      tableId="withdrawals"
    />
  );
};
