import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { TTableFilters } from '~/types/table';
import { baseQueryHandler } from '~/utils/apiRequest';
import { getFormData } from '~/utils/formData';

import {
  TClientDocuments,
  TDeleteFileParams,
  TPostWithdrawalConfirmParams,
  TReasons,
  TUpload,
  TUploadParam,
  TWithdrawalsData,
  TWithdrawalsExportData,
  TWithdrawalsExportPayload,
} from './withdrawals.types';

export const withdrawalsApi = createApi({
  reducerPath: 'withdrawalsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'Withdrawals',
    'Withdrawal',
    'WithdrawalsTableFilters',
    'ExportCsv',
    'Documents',
  ],
  endpoints: (builder) => ({
    getWithdrawals: builder.query<
      TDefaultPaginationResponse<TWithdrawalsData>,
      TDefaultParams
    >({
      query: (params) => ({
        url: '/clientsarea/crm/withdrawals/rest/withdrawal/',
        data: {
          ...params,
        },
        method: 'POST',
      }),
      providesTags: ['Withdrawals'],
    }),

    getWithdrawal: builder.query<TWithdrawalsData, { id: string }>({
      query: ({ id }) => ({
        url: `/rest/withdrawals/${id}/`,
      }),
      providesTags: ['Withdrawal'],
    }),

    getWithdrawalsTableFilters: builder.query<TTableFilters, void>({
      query: () => ({
        url: '/rest/withdrawals-filters/?table_name=withdrawals',
      }),
      providesTags: ['WithdrawalsTableFilters'],
    }),

    exportWithdrawalCsv: builder.mutation<
      TWithdrawalsExportData,
      TWithdrawalsExportPayload
    >({
      query: ({ withdrawals_ids, columns }) => ({
        url: `/clientsarea/crm/withdrawals/rest/withdrawal/bulk/export/csv/`,
        method: 'POST',
        data: { withdrawals_ids, columns },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          Notification.success({
            title: data?.message || 'Success',
          });
        } catch (e) {
          Notification.error({
            title: 'Error',
          });
        }
      },
      invalidatesTags: ['ExportCsv'],
    }),

    uploadFiles: builder.mutation<TUpload, TUploadParam>({
      query: ({ id, file }) => {
        const formData = getFormData([['withdrawal', String(id)]]);
        formData.data.append('file', file);

        return {
          url: `/clientsarea/crm/withdrawals/upload/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully uploaded',
          });
        } catch (e) {
          Notification.error({
            title: 'File uploading error ',
          });
        }
      },
      invalidatesTags: ['Withdrawal'],
    }),

    deleteFile: builder.mutation<void, TDeleteFileParams>({
      query: ({ withdrawalId, fileId }) => ({
        url: `/clientsarea/crm/withdrawals/upload/${fileId}/?withdrawal=${withdrawalId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'File deleting error ',
          });
        }
      },
      invalidatesTags: ['Withdrawal'],
    }),

    editWithdrawal: builder.mutation<
      void,
      { withdrawalId: string; data: Partial<Record<string, unknown>> }
    >({
      query: ({ withdrawalId, data }) => ({
        url: `/clientsarea/crm/withdrawals/${withdrawalId}/edit/`,
        method: 'PATCH',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Successfully edited',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Withdrawal'],
    }),

    postWithdrawalConfirm: builder.mutation<void, TPostWithdrawalConfirmParams>(
      {
        query: ({ id, confirm }) => {
          return {
            url: `/clientsarea/crm/withdrawals/${id}/confirm/${confirm}/`,
            method: 'POST',
            data: { confirm },
          };
        },
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled;
            Notification.success({
              title: 'Confirm completed successfully',
            });
          } catch (e) {
            console.error(e);
          }
        },
        invalidatesTags: ['Withdrawal'],
      },
    ),

    getHoldReasons: builder.query<TReasons[], void>({
      query: () => ({
        url: '/clientsarea/crm/withdrawals/rest/withdrawal/hold-reason/',
      }),
    }),

    getRejectReasons: builder.query<TReasons[], void>({
      query: () => ({
        url: '/clientsarea/crm/withdrawals/rest/withdrawal/reject-reason/',
      }),
    }),

    withdrawalActions: builder.mutation<
      void,
      { id: string; content?: string; type: string }
    >({
      query: ({ id, content, type }) => {
        return {
          url: `/clientsarea/crm/withdrawals/${id}/${type}/`,
          method: 'POST',
          data: { content },
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Withdrawal successfully transitioned',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Withdrawal'],
    }),

    getWithdrawalDocuments: builder.query<TClientDocuments[], { id: string }>({
      query: ({ id }) => ({
        url: `/clientsarea/crm/withdrawals/rest/withdrawal/${id}/documents/`,
      }),
      providesTags: ['Documents'],
    }),

    getWithdrawalDownloadClientDocument: builder.query<
      { url: string },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/clientsarea/crm/withdrawals/rest/withdrawal/documents/${id}/download/`,
      }),
    }),

    getWithdrawalDownloadUploadedFile: builder.query<
      { url: string },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/clientsarea/crm/withdrawals/upload/${id}/document/`,
      }),
    }),

    getWithdrawalExportToXML: builder.query<TWithdrawalsData, { id: string }>({
      query: ({ id }) => ({
        url: `/clientsarea/crm/withdrawals/rest/withdrawal/${id}/xml/get/`,
      }),
    }),

    withdrawalConvert: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/clientsarea/crm/withdrawals/rest/withdrawal/${id}/conversion/all/`,
          method: 'POST',
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Withdrawal successfully converted',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Withdrawal'],
    }),
  }),
});
export const {
  useLazyGetWithdrawalsQuery,
  useGetWithdrawalsTableFiltersQuery,
  useExportWithdrawalCsvMutation,
  useGetWithdrawalQuery,
  useUploadFilesMutation,
  useDeleteFileMutation,
  useEditWithdrawalMutation,
  usePostWithdrawalConfirmMutation,
  useLazyGetHoldReasonsQuery,
  useLazyGetRejectReasonsQuery,
  useWithdrawalActionsMutation,
  useGetWithdrawalDocumentsQuery,
  useLazyGetWithdrawalDownloadClientDocumentQuery,
  useLazyGetWithdrawalDownloadUploadedFileQuery,
  useLazyGetWithdrawalExportToXMLQuery,
  useWithdrawalConvertMutation,
} = withdrawalsApi;
